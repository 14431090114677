<template>
  <div id="eligibility-table" class="w-full">
    <div
      class="flex m-0 overflow-x-auto bg-neutral-light rounded-t-lg table-plan-heading scroll-snap-x-proximity"
    >
      <div
        class="p-2 text-xl cursor-pointer select-none min-w-48 scroll-snap-align-start scroll-snap-stop-normal"
        :class="
          activeTab === 0
            ? 'border-l border-r border-t border-b-0 bg-white rounded-t-lg'
            : 'border-b'
        "
        @click="clickTab(0)"
      >
        Flexible Spending Account
      </div>
      <div class="w-2 border-b"></div>
      <div
        class="p-2 text-xl cursor-pointer select-none min-w-48 scroll-snap-align-start scroll-snap-stop-normal"
        :class="
          activeTab === 1
            ? 'border-l border-r border-t border-b-0 bg-white rounded-t-lg'
            : 'border-b'
        "
        @click="clickTab(1)"
      >
        Dependent Care Account
      </div>
      <div
        class="box-content flex flex-row items-center justify-end flex-1 p-2 text-base border-b"
      >
        <div class="mx-2 my-0">
          <span
            class="inline-block my-0 mr-2 font-semibold text-primary-light flaticon-check-mark"
          />
          <p class="inline-block">Eligible</p>
        </div>
        <div class="mx-2 my-0">
          <span
            class="inline-block my-0 mr-2 font-semibold text-yellow-400 flaticon-attention-1"
          />
          <p class="inline-block">Potentially Eligible</p>
        </div>
        <div class="mx-2 my-0">
          <span
            class="inline-block my-0 mr-2 font-semibold text-red-500 flaticon-error"
          />
          <p class="inline-block">Not Eligible</p>
        </div>
      </div>
    </div>

    <div
      class="flex flex-row flex-wrap justify-around w-full border-l border-r"
    >
      <component
        v-for="letter in alphabet"
        :key="letter"
        :is="groups.includes(letter) ? 'a' : 'span'"
        :class="
          groups.includes(letter)
            ? 'hover:bg-primary hover:text-white'
            : 'text-neutral cursor-default select-none'
        "
        class="px-2 py-0 mx-0 my-2 rounded"
        :href="[groups.includes(letter) ? '#' + letter : '#']"
        >{{ letter }}</component
      >
    </div>
    <div id="table" class="overflow-hidden">
      <div v-for="group in groupedData" :key="group.group">
        <div
          class="flex items-center justify-between p-2 text-white bg-primary"
        >
          <h3 :id="group.group" class="text-2xl font-semibold">
            {{ group.group }}
          </h3>
          <a href="#" class="text-sm">
            Back to Top
            <span class="inline-block ml-1 flaticon-up-arrow" />
          </a>
        </div>
        <div
          class="flex flex-col px-4 pt-4 pb-4 pb-12 border-l border-r even:bg-neutral-light md:flex-row md:pb-4"
          v-for="item in group.items"
          :key="item.name"
        >
          <div
            class="flex items-center md:py-4 md:w-80 md:border-r"
            :class="{
              'md:border-primary-light': item.code === 'Q',
              'md:border-yellow-400': item.code === 'P',
              'md:border-red-500': item.code === 'N'
            }"
          >
            <h4 class="flex-1 inline-block font-semibold">{{ item.name }}</h4>
            <div
              class="inline-block text-2xl font-semibold"
              :class="{
                'flaticon-check-mark md:my-0 md:mr-4 md:ml-2 text-primary-light':
                  item.code === 'Q',
                'flaticon-attention-1 md:my-0 md:mr-4 md:ml-2 text-yellow-400':
                  item.code === 'P',
                'flaticon-error md:my-0 md:mr-4 md:ml-2 text-red-500':
                  item.code === 'N'
              }"
            />
          </div>
          <p class="mt-4 text-sm md:flex-1 md:mt-0 md:mr-0 md:mb-4 md:ml-4">
            {{ item.description }}
          </p>
        </div>
      </div>
      <div
        class="p-2 text-xs text-right text-white bg-neutral-900 rounded-b-lg"
      >
        Data from table provided by
        <a
          href="https://tax.thomsonreuters.com/site/hcet-ebia/rmr"
          class="inline-link"
          >Thomson Reuters</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  data() {
    return {
      activeTab: 0,
      tableData: [],
      groupedData: [],
      alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],
      groups: []
    };
  },
  props: {
    searchValue: String,
    searchEligible: Boolean,
    searchPotentiallyEligible: Boolean,
    searchNotEligible: Boolean
  },
  created() {
    this.tableData = require("@/assets/FSAExpenseData.json");

    this.groupedData = Object.values(
      this.tableData.reduce((acc, entry) => {
        let firstLetter = entry.name[0].toLocaleUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = { group: firstLetter, items: [entry] };
          this.groups.push(firstLetter);
        } else {
          acc[firstLetter].items.push(entry);
        }
        return acc;
      }, {})
    );
  },
  methods: {
    search() {
      // Settings for search.
      const options = {
        keys: ["name", "description"],
        // distance: 2000,
        threshold: 0.1,
        includeScore: true,
        useExtendedSearch: true
        // ignoreLocation: true
      };
      const fuse = new Fuse(this.tableData, options);

      // Use all tableData if there is no search value.
      if (this.searchValue !== "") {
        const result = fuse.search("'" + this.searchValue);
        // console.log(result);
        var dataToSort = result.map(a => a.item);
      } else {
        dataToSort = this.tableData;
      }

      // Filter for eligible, potentially eligible, or ineligible
      dataToSort = dataToSort.filter(item => {
        if (
          (this.searchEligible && item.code === "Q") ||
          (this.searchPotentiallyEligible && item.code === "P") ||
          (this.searchNotEligible && item.code === "N")
        ) {
          return true;
        } else {
          return false;
        }
      });

      // Sort data alphabetically by name
      dataToSort.sort(function(a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });

      // Group data by first letter in name for table
      this.groups = [];
      this.groupedData = Object.values(
        dataToSort.reduce((acc, entry) => {
          let firstLetter = entry.name[0].toLocaleUpperCase();
          if (!acc[firstLetter]) {
            acc[firstLetter] = { group: firstLetter, items: [entry] };
            this.groups.push(firstLetter);
          } else {
            acc[firstLetter].items.push(entry);
          }
          return acc;
        }, {})
      );
    },
    clickTab(tabIndex) {
      this.activeTab = tabIndex;

      if (tabIndex === 0) {
        this.tableData = require("@/assets/FSAExpenseData.json");
      } else if (tabIndex === 1) {
        this.tableData = require("@/assets/DCAExpenseData.json");
      }
      this.search();
    }
  },
  watch: {
    searchValue: function() {
      this.search();
    },
    searchEligible: function() {
      this.search();
    },
    searchPotentiallyEligible: function() {
      this.search();
    },
    searchNotEligible: function() {
      this.search();
    }
  }
};
</script>

<style scoped>
.scroll-snap-x-proximity {
  scroll-snap-type: x proximity;
}

.min-w-48 {
  min-width: 12rem;
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

.scroll-snap-stop-normal {
  scroll-snap-stop: normal;
}
</style>
