var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full",attrs:{"id":"eligibility-table"}},[_c('div',{staticClass:"flex m-0 overflow-x-auto bg-neutral-light rounded-t-lg table-plan-heading scroll-snap-x-proximity"},[_c('div',{staticClass:"p-2 text-xl cursor-pointer select-none min-w-48 scroll-snap-align-start scroll-snap-stop-normal",class:_vm.activeTab === 0
          ? 'border-l border-r border-t border-b-0 bg-white rounded-t-lg'
          : 'border-b',on:{"click":function($event){return _vm.clickTab(0)}}},[_vm._v(" Flexible Spending Account ")]),_c('div',{staticClass:"w-2 border-b"}),_c('div',{staticClass:"p-2 text-xl cursor-pointer select-none min-w-48 scroll-snap-align-start scroll-snap-stop-normal",class:_vm.activeTab === 1
          ? 'border-l border-r border-t border-b-0 bg-white rounded-t-lg'
          : 'border-b',on:{"click":function($event){return _vm.clickTab(1)}}},[_vm._v(" Dependent Care Account ")]),_vm._m(0)]),_c('div',{staticClass:"flex flex-row flex-wrap justify-around w-full border-l border-r"},_vm._l((_vm.alphabet),function(letter){return _c(_vm.groups.includes(letter) ? 'a' : 'span',{key:letter,tag:"component",staticClass:"px-2 py-0 mx-0 my-2 rounded",class:_vm.groups.includes(letter)
          ? 'hover:bg-primary hover:text-white'
          : 'text-neutral cursor-default select-none',attrs:{"href":[_vm.groups.includes(letter) ? '#' + letter : '#']}},[_vm._v(_vm._s(letter))])}),1),_c('div',{staticClass:"overflow-hidden",attrs:{"id":"table"}},[_vm._l((_vm.groupedData),function(group){return _c('div',{key:group.group},[_c('div',{staticClass:"flex items-center justify-between p-2 text-white bg-primary"},[_c('h3',{staticClass:"text-2xl font-semibold",attrs:{"id":group.group}},[_vm._v(" "+_vm._s(group.group)+" ")]),_vm._m(1,true)]),_vm._l((group.items),function(item){return _c('div',{key:item.name,staticClass:"flex flex-col px-4 pt-4 pb-4 pb-12 border-l border-r even:bg-neutral-light md:flex-row md:pb-4"},[_c('div',{staticClass:"flex items-center md:py-4 md:w-80 md:border-r",class:{
            'md:border-primary-light': item.code === 'Q',
            'md:border-yellow-400': item.code === 'P',
            'md:border-red-500': item.code === 'N'
          }},[_c('h4',{staticClass:"flex-1 inline-block font-semibold"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"inline-block text-2xl font-semibold",class:{
              'flaticon-check-mark md:my-0 md:mr-4 md:ml-2 text-primary-light':
                item.code === 'Q',
              'flaticon-attention-1 md:my-0 md:mr-4 md:ml-2 text-yellow-400':
                item.code === 'P',
              'flaticon-error md:my-0 md:mr-4 md:ml-2 text-red-500':
                item.code === 'N'
            }})]),_c('p',{staticClass:"mt-4 text-sm md:flex-1 md:mt-0 md:mr-0 md:mb-4 md:ml-4"},[_vm._v(" "+_vm._s(item.description)+" ")])])})],2)}),_vm._m(2)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content flex flex-row items-center justify-end flex-1 p-2 text-base border-b"},[_c('div',{staticClass:"mx-2 my-0"},[_c('span',{staticClass:"inline-block my-0 mr-2 font-semibold text-primary-light flaticon-check-mark"}),_c('p',{staticClass:"inline-block"},[_vm._v("Eligible")])]),_c('div',{staticClass:"mx-2 my-0"},[_c('span',{staticClass:"inline-block my-0 mr-2 font-semibold text-yellow-400 flaticon-attention-1"}),_c('p',{staticClass:"inline-block"},[_vm._v("Potentially Eligible")])]),_c('div',{staticClass:"mx-2 my-0"},[_c('span',{staticClass:"inline-block my-0 mr-2 font-semibold text-red-500 flaticon-error"}),_c('p',{staticClass:"inline-block"},[_vm._v("Not Eligible")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"text-sm",attrs:{"href":"#"}},[_vm._v(" Back to Top "),_c('span',{staticClass:"inline-block ml-1 flaticon-up-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 text-xs text-right text-white bg-neutral-900 rounded-b-lg"},[_vm._v(" Data from table provided by "),_c('a',{staticClass:"inline-link",attrs:{"href":"https://tax.thomsonreuters.com/site/hcet-ebia/rmr"}},[_vm._v("Thomson Reuters")])])}]

export { render, staticRenderFns }