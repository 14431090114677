import { render, staticRenderFns } from "./WhatsCovered.vue?vue&type=template&id=33d5ec3b&scoped=true&"
import script from "./WhatsCovered.vue?vue&type=script&lang=js&"
export * from "./WhatsCovered.vue?vue&type=script&lang=js&"
import style0 from "./WhatsCovered.vue?vue&type=style&index=0&id=33d5ec3b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d5ec3b",
  null
  
)

export default component.exports